<template>
    <p class="text-center disclaimer">
        Al usar este servicio aceptas nuestros 
        <v-btn text href="/terminos" color="link">términos de uso</v-btn> y la <v-btn text href="/privacidad" color="link">política de privacidad</v-btn>.
    </p>
</template>
<script>
  export default {
    name: 'FooterAuth',
  }
</script>
<style scoped>
.disclaimer{
  margin-top: 60px !important;
}
</style>