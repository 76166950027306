<template>
    <v-navigation-drawer
      v-model="opened"
      height="100vh"
      right
      floating
      fixed
      temporary
      hide-overlay
      class="drawer-container"
    >
      <v-card flat v-if="collaborator">
        <v-toolbar dense flat>
            <v-toolbar-title>Detalles</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="opened = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Rol</v-list-item-title>
                        <v-list-item-subtitle>{{ collaborator.role.description }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Nombre</v-list-item-title>
                        <v-list-item-subtitle>{{ collaborator.name }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Email</v-list-item-title>
                        <v-list-item-subtitle>{{ collaborator.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Key</v-list-item-title>
                        <v-list-item-subtitle>{{ collaborator.collaborator_key }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Terminal de venta u operación</v-list-item-title>
                        <v-list-item-subtitle>{{ collaborator.terminal.name }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-row
                    align="center"
                    justify="space-around"
                >
                <v-btn
                    outlined
                    rounded
                    color="primary"
                    @click.stop="editItem"
                    >
                    Editar
                    </v-btn>
            </v-row>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
</template>
<script>
export default {
    name: 'CollaboratorInfoDrawer',
    data: () => ({
        opened: false,
        collaborator: null,
    }),
    methods:{
        editItem(){
            this.$emit('editionClicked', this.collaborator); 
        },
        show(collaborator){
            this.opened = true;
            this.collaborator = collaborator;
        }
    }
}
</script>
<style scoped>
.drawer-container{
    width: 350px !important;
}
</style>