<template>
    <div class="text-center ma-2" v-if="open">
        <v-snackbar
        v-model="open"
        :timeout="timeout"
        :multi-line="multi"
        color="primary">
        <v-card flat color="primary">
            <v-card-text :v-html="message"></v-card-text>
        </v-card>
        <template v-slot:action="{ attrs }">
            <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="open = false">
                Cerrar
            </v-btn>
        </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
    name: 'SnackBarMessage',
    props: {
        timeout: {
            type: Number,
            default: 5000
        },
        multi: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        open: false,
        message: "",
    }),
    methods: {
 	 show(message){
		this.message = message;
        this.open = true;
 	 },
    }
}
</script>
<style scoped>
</style>