<template>
    <v-btn
                v-show="true"
                :color="color"
                dark
                fixed
                bottom
                right
                fab
                @click="buttonClicked"
              >
                <v-icon>{{icon}}</v-icon>
              </v-btn>
</template>
<script>
export default {
    name: "FloatingButton",
    props: {
        icon: String,
        color: String
    },
    data: () => ({
        searchValue: '',
    }),
    methods: {
        buttonClicked(){
            this.$emit("clickFloating");
        }
    }
}
</script>
<style scoped>
</style>