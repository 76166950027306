<template>
  <div class="section-container">
    <div class="section-header">
      <h1 class="section-title">
        Actividad
      </h1>
    </div>
    
  </div>
</template>

<script>
  export default {
    name: 'CyphraWebActivity',
    data() { return { }; },
    mounted() { 
      this.$bus.$emit('selectCurrentMenuOption',9);
    },
    methods: { },
  };
</script>

<style scoped lang="scss">
  @import './../assets/styles/sections-app.css';
</style>