<template>
<v-layout justify-center>
      <v-flex xs12 sm12 md12 lg12 class="mt-12">
        <v-card flat color="background">
          <!-- Push Contents In and Put Space Between Flex Items Using Grid List -->
          <v-container fluid grid-list-lg>
            <prepaids-toolbar title="Prepagos" placeholderSearch="Buscar" @searchItem="searchItemData" @filterChanged="filterWasChanged"/>
            
            <v-layout row wrap white>
              <v-flex lg3 md3 xl3 sm3>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs"
                      readonly
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    color="primary"
                    @change="dateSelected"
                    @input="menu1 = false">
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex lg12 md12 xl12 sm12>
                <v-data-table
                  :headers="headers"
                  :items="prepaids"
                  :items-per-page="10"
                  item-key="id"
                  sort-by="id"
                  @click:row="rowClicked">
                  <template v-slot:createdAt="{ item }">
                      {{ formattedDate(item.createdAt) }}
                  </template>
                  <template v-slot:visit_date="{ item }">
                      {{ formattedDate(item.visit_date) }}
                  </template>
                  <template v-slot:payment_method="{ item }">
                      {{ item.payment_method.method_id == 'card' ? 'Tarjeta':'OXXO' }}
                  </template>
                  <template v-slot:amount="{ item }">
                      $ {{ item.amount }}
                  </template>
                  <template v-slot:prepaid_status="{ item }">
                      {{ statusPrepaid(item.prepaid_status) }}
                  </template>
                  <template v-slot:topUp_status="{ item }">
                      {{ statusRecharge(item.topUp_status) }}
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>

            <confirm-modal ref="confirm" @interface="componentConfirmResult" />
            <prepaid-info-drawer ref="drawerPrepaid" @toggleCancel="toggleCancelPrepaid"/>

          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
</template>
<script>
  import axios from 'axios';
  import PrepaidsToolbar from './Toolbars/PrepaidsToolbar'
  import ConfirmModal from './Dialogs/ConfirmModal'
  import PrepaidInfoDrawer from './Drawers/PrepaidInfoDrawer'
  import NoResults from './NoResults'
  import moment from 'moment'
  import { format, parseISO } from 'date-fns'

  const REQUEST_ITEM_DELETE = 1;
  const REQUEST_ITEM_TOGGLE_ACTIVATE = 2;

  export default {
    components: {
      PrepaidsToolbar,
      ConfirmModal,
      PrepaidInfoDrawer,
      NoResults
    },
    data: (vm) => ({
      date: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      menu1: false,
      filterSelected: '', //Todos los items
      methodType: 'all',
      operationType: 'all',
      collaborators: [],
      terminals: [],
      currentSearch: '',
      publicPath: process.env.VUE_APP_URL,
      headers: [
          {
            text: 'Creado',
            align: 'start',
            value: 'createdAt',
            groupable: false,
          },
          { text: 'Clave', value: 'prepaid_code', groupable: false},
          { text: 'Visita', value: 'visit_date', groupable: false },
          { text: 'Generado por', value: 'user_name', groupable: false },
          { text: 'Email', value: 'user_email', groupable: false },
          { text: 'Método', value: 'payment_method', groupable: false },
          { text: 'Monto', value: 'amount', groupable: false },
          { text: 'Estatus', value: 'prepaid_status', groupable: false },
          { text: 'Recarga', value: 'topUp_status', groupable: false },
        ],
        prepaids: [],
    }),

    created () {
      this.initialize();
      this.loadListeners();
    },

    mounted() {
      //this.$store.dispatch('savePath', 'transactions');
      this.$bus.$emit('selectCurrentMenuOption',2);
    },
    computed: {
      computedDateFormattedMomentjs () {
        return this.date ? this.dateHelpers.formatInput(this.date) : ''
      },
      activeBusiness(){
        return this.$store.getters.business;
      },
    },
    methods: {
      toggleCancelPrepaid(item_id){
          this.$bus.$emit('toggleLoading', true);
          axios.patch('prepaids/toggleCancel',{prepaid_id: item_id}).then(response => {
              this.$bus.$emit('toggleLoading', false);
              this.$refs.drawerPrepaid.updateStatus(response.data);
              this.listPrepaids();
          }).catch(err => {

          });
      },
      rowClicked(row){
        this.showItemInfo(row);
      },
      statusPrepaid(status){
        const statusPrepaids = {0: 'Por pagar', 1: 'Pagado', 2:'Cancelado'};
        return statusPrepaids[status];
      },
      statusRecharge(status){
        const statusTag = {0: 'Por abonar', 1: 'Abonado'};
        return statusTag[status];
      },
      formattedDate(date){
        //const fDate = moment(date).utcOffset(0).format('DD MMMM YYYY HH:mm:ss');
        const fDate = this.dateHelpers.formatTable(date)
        return fDate;
      },
      dateSelected(date){
        this.listPrepaids();
      },
      viewBusiness(business){
        this.$store.dispatch('saveBusiness', business)
      },
      filterWasChanged(filterSelected,filterValue){
        console.log(filterSelected);
        console.log(filterValue);
        if(filterSelected == 'statusFilter'){
          this.operationType = filterValue;
        }else{
          this.methodType = filterValue;
        }
        //this.filterSelected = selectedFilter;
        this.listPrepaids();
      },
        loadListeners () {
              this.$bus.$off('refreshCollaboratorsList');

              this.$bus.$on('refreshCollaboratorsList', (isLoading) => {
                //this.listCollaborators();
              });
        },
        showItemInfo(item){
          this.$refs.drawerPrepaid.show(item);
        },
        deleteItemConfirmed(item_id, isConfirmated){
          if(isConfirmated){
            this.$bus.$emit('toggleLoading', true);
            axios.post('collaborators/delete',{item_id}).then(response => {
                this.$bus.$emit('toggleLoading', false);
                //this.listCollaborators();
            }).catch(err => {

            });
          }
        },
        toggleActivationItemConfirmed(item_id, isConfirmated){
          if(isConfirmated){
            this.$bus.$emit('toggleLoading', true);
            axios.patch('collaborators/toggleActivation',{item_id}).then(response => {
                this.$bus.$emit('toggleLoading', false);
                //this.listCollaborators();
            }).catch(err => {

            });
          }
        },
        componentConfirmResult(isConfirmated, item_id, component){
          switch (component) {
                case REQUEST_ITEM_DELETE :
                  this.deleteItemConfirmed(item_id, isConfirmated);
                break;
                case REQUEST_ITEM_TOGGLE_ACTIVATE :
                  this.toggleActivationItemConfirmed(item_id, isConfirmated);
                break;
            }
        },
        editItem(item){
          const itemRow = {...item};
          this.$refs.addEditModal.edit(itemRow);
        },
        deleteItem(item){
          let data = {
              title: "Eliminar",
              message: `¿Deseas eliminar a <<b>${item.name}</b>>? No podrás deshacer la acción.`,
              confirmText: "Si, Eliminar"
          }
          this.$refs.confirm.open(data, REQUEST_ITEM_DELETE, item._id);
        },
        toggleActivationItem(item){
          const message = item.status ? `<<b>${item.name}</b>> no podrá ingresar a la aplicación móvil. ¿Deseas continuar?`
          : `<<b>${item.name}</b>> podrá usar nuevamente la aplicación. ¿Deseas continuar?`;
          let data = {
              title: item.status ? "Bloquear colaborador" : "Reactivar colaborador",
              message: message,
              confirmText: item.status ? "Si, Bloquear" : "Si, Reactivar"
          }
          this.$refs.confirm.open(data, REQUEST_ITEM_TOGGLE_ACTIVATE, item._id);
        },
        addNewCollaborator(){
          this.$refs.addEditModal.open();
        },
        searchItemData(search){
          this.currentSearch = search;
          this.listPrepaids();
        },
        listPrepaids(){
          let me=this;
          const userData = this.$store.state.user
          const search = this.currentSearch;
          this.$bus.$emit('toggleLoading', true);
          if(userData.role == "admin"){
            axios.get('users/query?_id='+userData._id)
            .then(function (responseUser){
              const businessId = responseUser.data.business ;
              axios.get(`prepaids/list/${businessId}/${me.operationType}/${me.methodType}?search=${search}&date=${me.date}`).then(response => {
                  me.prepaids = response.data;
                  me.$bus.$emit('toggleLoading', false);
              }).catch(err => { });
            })

          }else{
            const businessId = this.activeBusiness._id;
            axios.get(`prepaids/list/${businessId}/${this.operationType}/${this.methodType}?search=${search}&date=${this.date}`).then(response => {
                this.prepaids = response.data;
                this.$bus.$emit('toggleLoading', false);
            }).catch(err => { });
          }


         


        },
        initialize () {
            this.listPrepaids();
        },
    },
  }
</script>
<style scoped>
.flex-empty div {
  width: 350px;
}
.card-item{
  min-width: 320px !important;
  /*flex-basis: initial !important;
  max-width: initial !important;*/
}
#card-container, .data-containe{
  height: 55vh;
  overflow: auto;
  width: 100%;
  padding-right: 0 !important;
  margin-right: 0 !important;
}
.v-list-item__action{
  margin-left: 0 !important;
}
.item-title:hover{
  text-decoration: underline;
}
</style>