<template>
    <v-navigation-drawer
        v-model="opened"
        height="100vh"
        right
        floating
        fixed
        temporary
        hide-overlay
        class="drawer-container">

        <v-toolbar dense flat>
            <v-toolbar-title>Detalles</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="opened = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <div class="order-drawer-container">
            <div class="order-item-data">
                <span>Fecha</span>
                <span>{{formatDate( order.createdAt ) }}</span>
            </div>
            <div class="order-item-data">
                <span>Hora</span>
                <span>{{ formatTime(order.createdAt) }}</span>
            </div>
            <div class="order-item-data">
                <span>ID</span>
                <span>{{ order.alias }}</span>
            </div>
            <div class="order-item-data">
            </div>
            <div class="order-item-data">
                <span>Tag</span>
                <span>{{ transaction.tag_id }}</span>
            </div>
            <div class="order-item-data">
                <span>Folio</span>
                <span>{{order.folio}}</span>
            </div>
            <div class="order-item-data">
                <span>Pago</span>
                <span>{{ formatCurrency(transaction.amount) }}</span>
            </div>
            <div class="order-item-data">
            </div>
            <div class="order-item-data">
                <span>Punto de venta</span>
                <span>{{order.terminal_id.name}}</span>
            </div>
            <!--047CA81AC85B811732726907978-->
            <div class="div-line-order"></div>

            <div class="product-item-data"
            v-for="item in productsGrup" :key="item.product">
                <span>{{item.cant}} x {{ item.product }} </span>
                <span>{{ formatCurrency(item.total) }}</span>
            </div>
            
                    
        </div>
    </v-navigation-drawer>
    
</template>
<script>
    import axios from 'axios'
    export default {
        name: 'CyphraWebOrderDrawer',
        data() {
            return { 
                opened: false,
                order: null,
                productsGrup:[],
                transaction: null, 
            };
        },
        mounted() { },
        methods: {
            formatCurrency(value) {
                if (!value) return '0.00';
                    return new Intl.NumberFormat('es-MX', {
                        style: 'currency', 
                        currency: 'MXN',  
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(value);
            },
            formatDate(isoString) {
                const date = new Date(isoString); // Convierte la cadena en un objeto Date
                const day = String(date.getDate()).padStart(2, '0'); // Día con 2 dígitos
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes con 2 dígitos (0-indexado)
                const year = date.getFullYear(); // Año completo
                return `${day}/${month}/${year}`; // Combina en el formato deseado
            },
            formatTime(isoString) {
                const date = new Date(isoString); // Convierte la cadena en un objeto Date
                let hours = date.getHours(); // Obtiene las horas en formato 24 horas
                const minutes = String(date.getMinutes()).padStart(2, '0'); // Asegura 2 dígitos para los minutos
                const ampm = hours >= 12 ? 'PM' : 'AM'; // Determina AM o PM
                hours = hours % 12 || 12; // Convierte a formato 12 horas
                return `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`; // Combina en el formato deseado
            },
            show(order){
                this.opened = true;
                this.order = order;
                this.getProducts(order.products)
                this.getTransaction(order.alias)
            }, 
            getProducts(products){
                const grouped = {};
                products.forEach(product => {
                    const { name, price } = product;
                    if (!grouped[name]) {
                        grouped[name] = { cant: 0, product: name, total: 0 };
                    }
                    grouped[name].cant += 1;          
                    grouped[name].total += price;
                });
                this.productsGrup =  Object.values(grouped);
            },
            async getTransaction(id){
                const me = this
                me.transaction = null
                await axios.get(`transactions/getByOrder?order=${id}`)
                .then(function (response){
                    console.log("XXXX")
                    me.transaction = response.data
                }).catch( function (_){
                    console.log("====")
                   console.log(me.transaction)
                })
            }
        },
    };
</script>
<style scoped lang="scss">
    .drawer-container{
        width: 350px !important;
    }
    .order-drawer-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .order-item-data{
        display: flex;
        flex-direction: column;
        width: 49%;
        padding: 15px;
    }
    .div-line-order{
        width: 100%;
        margin:15px;
        border-bottom: 1px solid #D3D2D3;
    }

    .product-item-data{
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        justify-content: space-between;
    }
</style>
