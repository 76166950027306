import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import HomeBusiness from '../views/HomeBusiness.vue'
import PrepaidBusiness from '../views/PrepaidBusiness.vue'
import Login from '../views/Login.vue'
import Recover from '../views/Recover.vue'
import Reset from '../views/Reset.vue'
import Businesses from './../components/Businesses'
import Colaborators from './../components/Colaborators'
import Pos from './../components/Pos'
import Transactions from './../components/Transactions'
import Refunds from './../components/Refunds'
import Prepaids from './../components/Prepaids'
import Catalogue from './../components/Catalog'
import Category from './../components/Category'
import Extra from './../components/Extra'
import Orders from './../components/Orders'
import Activity from './../components/Activity'
import Cuts from './../components/Cuts'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      master: true,
      admin: true,
      grocer: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta:{
      free: true
    }
  },
  {
    path: '/recoverPassword',
    name: 'recover',
    component: Recover,
    meta:{
      free: true
    }
  },
  {
    path: '/resetPassword/:token',
    name: 'resetPassword',
    component: Reset,
    meta:{
      free: true
    }
  },
  {
    path: '/business',
    name: 'business',
    component: Businesses,
    meta: {
      master: true,
      admin: true
    }
  },
  {
    path: '/pagos/:business_url',
    name: 'business_prepaid',
    component: PrepaidBusiness,
    meta: {
      free: true
    }
  },
  {
    path: '/businessDashboard',
    name: 'businessDashboard',
    component: HomeBusiness,
    meta: {
      master: true,
      admin: true
    }
  },
  {
    path: '/collaborators',
    name: 'collaborators',
    component: Colaborators,
    meta: {
      master: true,
      admin: true
    }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: Transactions,
    meta: {
      master: true,
      admin: true
    }
  },
  {
    path: '/refunds',
    name: 'refunds',
    component: Refunds,
    meta: {
      master: true,
      admin: true
    }
  },
  {
     path: '/prepaids',
     name: 'prepaids',
     component: Prepaids,
     meta: {
       master: true,
       admin: true
     }
  },
  {
    path: '/pos',
    name: 'pos',
    component: Pos,
    meta: {
      master: true,
      admin: true
    }
  },
  {
    path: '/catalogue',
    name: 'catalogue',
    component: Catalogue,
    meta: {
      master: true,
      admin: true
    }
  },
  {
    path: '/category',
    name: 'category',
    component: Category,
    meta: {
      master: true,
      admin: true
    }
  },
  {
    path: '/extra',
    name: 'extra',
    component: Extra,
    meta: {
      master: true,
      admin: true
    }
  },
  {
    path: '/activity',
    name: 'activity',
    component: Activity,
    meta: {
      master: true,
      admin: true
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
    meta: {
      master: true,
      admin: true
    }
  },
  {
    path: '/cuts',
    name: 'cuts',
    component: Cuts,
    meta: {
      master: true,
      admin: true
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.middlewareCheck)){
    next()
  }else if(to.matched.some(record => record.meta.free)){
    next()
  }else if(store.state.user && store.state.user.role == 'master'){
    if(to.matched.some(record => record.meta.master)){
      next()
    }
  }else if(store.state.user && store.state.user.role == 'admin'){
    if(to.matched.some(record => record.meta.admin)){
      next()
    }
  }else if(store.state.user && store.state.user.role == 'grocer'){
    if(to.matched.some(record => record.meta.grocer)){
      next()
    }
  }else{
    next({name: 'login'})
  }
});

export default router
