<template>
    <v-navigation-drawer
      v-model="opened"
      height="100vh"
      right
      floating
      fixed
      temporary
      hide-overlay
      class="drawer-container"
    >
      <v-card flat v-if="refund">
        <v-toolbar dense flat>
            <v-toolbar-title>Detalles</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="opened = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-list>
                <v-layout justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Creado</v-list-item-title>
                                <v-list-item-subtitle>{{ dateHelpers.formatTable(refund.createdAt) }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>ID reembolso</v-list-item-title>
                                <v-list-item-subtitle>{{refund.refund_id}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                </v-layout>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>ID tag</v-list-item-title>
                        <v-list-item-subtitle>{{ refund.tag_id }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Usuario</v-list-item-title>
                        <v-list-item-subtitle>{{ refund.user_name }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Email</v-list-item-title>
                        <v-list-item-subtitle>{{ refund.user_email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-layout justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Saldo</v-list-item-title>
                                <v-list-item-subtitle>{{ numberHelpers.formatMoney(refund.tag_balance) }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Comisión</v-list-item-title>
                                <v-list-item-subtitle>{{ numberHelpers.formatMoney(refund.refund_fee) }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                </v-layout>

                <v-layout justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Reembolso</v-list-item-title>
                                <v-list-item-subtitle>{{ numberHelpers.formatMoney(refund.amount) }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Fecha de reembolso</v-list-item-title>
                                <v-list-item-subtitle>{{ dateHelpers.formatTable(refund.refund_date) }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                </v-layout>

                <v-layout justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Método</v-list-item-title>
                                <v-list-item-subtitle>{{ refund.refund_method.name }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Estatus</v-list-item-title>
                                <v-list-item-subtitle>{{ refund.status ? 'Reembolsado': 'Pendiente' }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                </v-layout>

                <template v-if="refund.refund_method.method_id == 'transfer'">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Banco</v-list-item-title>
                            <v-list-item-subtitle>{{ refund.bank }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Número de tarjeta</v-list-item-title>
                            <v-list-item-subtitle>{{ refund.card }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Clabe o número de cuenta</v-list-item-title>
                            <v-list-item-subtitle>{{ refund.account }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Comentarios</v-list-item-title>
                        <v-list-item-subtitle>{{ refund.comments || '-' }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-row
                    align="center"
                    justify="space-around"
                >
                <v-switch
                    @click.native="switchClicked()"
                    v-model="refundDone"
                    label="Reembolso realizado"
                    ></v-switch>
            </v-row>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
</template>
<script>
import moment from 'moment'
export default {
    name: 'RefundInfoDrawer',
    data: () => ({
        opened: false,
        refund: null,
        refundDone: false,
    }),
    methods:{
        switchClicked(){
            this.$emit('toggleSwitch', this.refund._id, true); 
        },
        updateStatus(updatedRefund){
            console.log(updatedRefund);
            this.refund.status = updatedRefund.status;
        },
        editItem(){
            //this.$emit('editionClicked', this.collaborator); 
        },
        show(refund){
            this.opened = true;
            this.refund = refund;
            this.refundDone = refund.status;
        },
    }
}
</script>
<style scoped>
.drawer-container{
    width: 350px !important;
}
</style>