<template>
    <v-navigation-drawer
      v-model="opened"
      height="100vh"
      right
      floating
      fixed
      temporary
      hide-overlay
      class="drawer-container"
    >
      <v-card flat v-if="prepaid">
        <v-toolbar dense flat>
            <v-toolbar-title>Detalles</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="opened = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-list>
                <v-layout justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Creado</v-list-item-title>
                                <v-list-item-subtitle>{{ dateHelpers.formatTable(prepaid.createdAt) }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>ID prepago</v-list-item-title>
                                <v-list-item-subtitle>{{prepaid._id}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Clave prepago</v-list-item-title>
                                <v-list-item-subtitle>{{ prepaid.prepaid_code }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Visita</v-list-item-title>
                                <v-list-item-subtitle>{{ dateHelpers.formatTable(prepaid.visit_date) }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                </v-layout>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Generado por</v-list-item-title>
                        <v-list-item-subtitle>{{ prepaid.user_name }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Email</v-list-item-title>
                        <v-list-item-subtitle>{{ prepaid.user_email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Teléfono</v-list-item-title>
                        <v-list-item-subtitle>{{ prepaid.user_phone }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-layout justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Método</v-list-item-title>
                                <v-list-item-subtitle>{{ prepaid.payment_method.name == 'card' ? 'Tarjeta' : 'OXXO' }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Monto</v-list-item-title>
                                <v-list-item-subtitle>{{ numberHelpers.formatMoney(prepaid.amount) }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                </v-layout>

                <v-layout justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Estatus</v-list-item-title>
                                <v-list-item-subtitle>{{ statusPrepaid(prepaid.prepaid_status)}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Fecha de pago</v-list-item-title>
                                <v-list-item-subtitle>{{ prepaid.paid_date ? dateHelpers.formatTable(prepaid.paid_date) : '-' }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                </v-layout>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Referencia gateway</v-list-item-title>
                        <v-list-item-subtitle>{{ prepaid.conekta_order_id }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-layout justify-center>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Recarga</v-list-item-title>
                                <v-list-item-subtitle>{{ statusRecharge(prepaid.topUp_status)}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                    <v-flex xs6 sm6 md6 lg6>
                         <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Fecha de recarga</v-list-item-title>
                                <v-list-item-subtitle>{{ prepaid.topUp_date ? dateHelpers.formatTable(prepaid.topUp_date) : '-' }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-flex>
                </v-layout>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Abonado a</v-list-item-title>
                        <v-list-item-subtitle>{{ prepaid.tag_id || '-' }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Comentarios</v-list-item-title>
                        <v-list-item-subtitle>{{ prepaid.comments || '-' }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>


            </v-list>
        </v-card-text>
        <v-card-actions v-if="prepaid.prepaid_status <= 0">
            <v-row
                    align="center"
                    justify="space-around"
                >
                <v-btn
                    text
                    rounded
                    color="purple"
                    @click.stop="cancelPrepaid"
                    >
                    Cancelar prepago
                    </v-btn>
            </v-row>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
</template>
<script>
import moment from 'moment'
export default {
    name: 'PrepaidInfoDrawer',
    data: () => ({
        opened: false,
        prepaid: null,
    }),
    methods:{
        statusPrepaid(status){
            const statusPrepaids = {0: 'Por pagar', 1: 'Pagado', 2:'Cancelado'};
            return statusPrepaids[status];
        },
        statusRecharge(status){
            const statusTag = {0: 'Por abonar', 1: 'Abonado'};
            return statusTag[status];
        },
        cancelPrepaid(){
            this.$emit('toggleCancel', this.prepaid._id, true); 
        },
        updateStatus(updatedPrepaid){
            this.prepaid.prepaid_status = updatedPrepaid.prepaid_status;
        },
        show(prepaid){
            this.opened = true;
            this.prepaid = prepaid;
        },
    }
}
</script>
<style scoped>
.drawer-container{
    width: 350px !important;
}
</style>