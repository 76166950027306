<template>
  <div class="section-container">
    <div class="section-header">
      <h1  class="section-title">
        Extras
      </h1>

      <div class="section-filter">
        <v-text-field
          class="section-search"
          hide-details
          placeholder="Buscar"
          single-line
          clearable>
          <template v-slot:append>
            <unicon name="search" fill="#5A5A5A"></unicon>
          </template>
        </v-text-field>
        <div class="section-filter-down">
          <div class="section-filter-element">
            <span>POS:</span>
            <v-select
              class="select-filter"
              dense
              v-model="selected_filter_pos"
              :items="filter_pos"
              item-text="text"
              item-value="value">
            </v-select>
          </div>
        </div>
      </div>

    </div>
    
  </div>
</template>

<script>
import axios from 'axios';
  export default {
    name: 'CyphraWebExtra',
    data() {
      return { 
        selected_filter_pos: "todos",
        filter_pos: [
            {value: 'todos', text: 'Todos'},
        ],
      };
    },
    mounted() { this.$bus.$emit('selectCurrentMenuOption',12); this.getInfo() },
    methods: {
      getInfo(){
        const me = this
        const userData = this.$store.state.user
        if(userData.role == "admin"){
          axios.get('users/query?_id='+userData._id)
          .then(function (responseUser){
            me.getPostList(responseUser.data.business)
          })
        }else{
          me.getPostList((this.$store.state.business)._id)
        }
      },

      getPostList(bussinesId){
        const me = this
        axios.get('terminals/listpost?business_id='+bussinesId)
          .then(function (responsePos){
            for(const item of responsePos.data){
              me.filter_pos.push({
                value: item._id, text: item.name
              })
            }
            
          })
      }
    },
  };
</script>

<style scoped lang="scss">
  @import './../assets/styles/sections-app.css';
</style>