<template>
    <v-dialog
    v-model="dialog"
    max-width="300"
  >
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>
          {{title}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text v-html="message"></v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
		  	color="secondary"
            text
			rounded
			@click.native="close()">{{cancelText}}</v-btn>
            <v-spacer></v-spacer>
          <v-btn 
		  	color="purple"
            text
			@click.native="acceptFunction()">{{confirmText}}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
    name: 'ConfirmModal',
    data: () => ({
        dialog: false,
        message: "",
		title: "",
		confirmText: "Sí, confirmar",
		cancelText: "No, cancelar",
    	comfirm: false,
    	COMPONENT_RESULT: 0,
    	id_request: 0
    }),
    methods: {
        /**
  	  * open description
  	  * Habre el modal.
  	  */
 	 open(data, request, id){
		console.log(data);
 	 	this.COMPONENT_RESULT = request
 	 	this.id_request = id
 	 	this.dialog = true
 	 	this.confirm = false
 	 	this.message = data.message
		this.title = data.title
		this.confirmText = data.confirmText ? data.confirmText : this.confirmText;
 	 	this.cancelText = data.cancelText ? data.cancelText : this.cancelText;
 	 },

 	 /**
 	  * close description
 	  * Metodo para cerrar el dialogo
 	  */
 	 close(){
 	 	this.dialog = false
 	 	this.confirm = false
 	 	this.message = ""
		this.title = ""
		this.confirmText = "Sí, confirmar";
		this.cancelText = "No, cancelar";
 	 	this.$emit('interface', this.confirm , this.id_request, this.COMPONENT_RESULT) 
 	 },

 	 /**
 	  * acceptFunction description
 	  * Comfirmar el dialogo
 	  */
 	 acceptFunction(){
 	 	this.confirm = true
 	 	this.dialog = false
 	 	this.message = ""
 	 	this.title = ""
 	 	this.$emit('interface', this.confirm, this.id_request, this.COMPONENT_RESULT) 
 	 },
    }
}
</script>
<style scoped>
>>> .v-toolbar__title{
    margin: 0 auto;
    font-size: 14px;
    font-weight: bold;
}
>>> .v-card__text{
    color: #000 !important;
}
</style>