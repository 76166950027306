<template>
    <v-footer padless>
        <v-col
        class="text-center"
        cols="12"
        >
        &copy; All rights reserved. <strong>Ciphra</strong> {{ new Date().getFullYear() }}
        </v-col>
  </v-footer>
</template>
<script>
export default {
    
}
</script>
<style scoped>
footer{
  background: transparent !important;
}
</style>