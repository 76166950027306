<template>
  <div class="section-container">
    <div class="section-header">
      <h1  class="section-title">
        Cortes de caja
      </h1>

      <div class="section-filter">
        <v-text-field
          class="section-search"
          hide-details
          placeholder="Buscar"
          single-line
          clearable>
          <template v-slot:append>
            <unicon name="search" fill="#5A5A5A"></unicon>
          </template>
        </v-text-field>
        <div class="section-filter-down">
          <div class="section-filter-element">
            <span>POS:</span>
            <v-select
              class="select-filter"
              dense
              v-model="selected_filter_pos"
              :items="filter_pos"
              item-text="text"
              item-value="value">
            </v-select>
          </div>
        </div>
      </div>
    </div>


    <div class="section-table">
      <v-data-table
        :headers="headers"
        :items="cuts"
        :items-per-page="cuts.length"
        hide-default-footer>
        <template #item="{ item }">
          <tr> 
            <td>{{ formattedDate(item.createdAt) }}</td>
            <td>{{ item.terminal_id.name }}</td>
            <td>{{ item.cut_number }}</td>
            <td>{{ item.sum_cash }}</td>
            <td>{{ item.sum_card }}</td>
            <td>{{ item.sum_total }}</td>
          </tr>
        </template>
      </v-data-table>
      <div v-if="showBtnMore"  class="btnMoreData" >
        <span @click="getCuts(true)">Mostrar mas +</span>
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'CyphraWebCuts',
  data() {
    return { 
        currentSearch: '',
        selected_filter_pos: "todos",
        filter_pos: [
            {value: 'todos', text: 'Todos'},
        ],

        headers: [
          { text: 'Creado', value: 'createdAt' },
          { text: 'POS', value: 'name' },
          { text: 'No. Corte', value: 'cut_number' },
          { text: 'Monto tarjeta', value: 'sum_cash' },
          { text: 'Monto efectivo', value: 'sum_card' },
          { text: 'Total', value: 'sum_total' },
        ],
        cuts: [],
        businessId:"",
        currentPage:1,
        totalPage:0, 
        showBtnMore:false

     };
  },
  watch: {
    selected_filter_pos(newVal, oldVal) {
      this.getCuts(false)  
    },
    currentSearch(newVal, oldVal) {
      this.getCuts(false)  
    },
  },
  created () {
    this.loadListeners();
    
  },
  mounted() { 
    this.$bus.$emit('selectCurrentMenuOption',8);
    this.getInfo();
  },
  methods: { 

    loadListeners () {
      this.$bus.$off('refreshList');
      this.$bus.$on('refreshList', (isLoading) => {  
        this.getInfo();
      });
    },

    async getInfo(){
      const me = this
      me.cuts=[]
      const userData = this.$store.state.user
      if(userData.role == "admin"){
        await axios.get('users/query?_id='+userData._id)
        .then(function (responseUser){
          me.businessId = responseUser.data.busines
        })
      }else{
        me.businessId = (this.$store.state.business)._id
      }
      me.getPostList(me.businessId)
    },

    getPostList(bussinesId){
      const me = this
      axios.get('terminals/listpost?business_id='+bussinesId)
        .then(function (responsePos){
          for(const item of responsePos.data){
            me.filter_pos.push({
              value: item._id, text: item.name
            })
          }
          me.getCuts(false)
        })
    }, 


    getCuts(isMore){
      const me = this
      let urlApi = ''
      let page = isMore ? this.currentPage + 1 : 1
      if(this.selected_filter_pos == "todos"){
        urlApi = 'cuts/listByBusiness?id=' + this.businessId +
                '&page=' + page +
                '&limit=20' +
                '&search=' + this.currentSearch
      }else{
        urlApi = 'cuts/listByPos?terminal_id=' + this.selected_filter_pos +
                '&page=' + page +
                '&limit=20' +
                '&search=' + this.currentSearch
      }
      axios.get(urlApi)
      .then(function (responseCuts){
        if(!isMore) me.orders = []
        me.totalPage = responseCuts.data.totalPages
        me.currentPage = responseCuts.data.currentPage
        for(const item of responseCuts.data.cuts){
          me.cuts.push(item)
        }
        if(me.totalPage == me.currentPage) me.showBtnMore = false
        else me.showBtnMore = true
      } )
    },



    formattedDate(date){
      const fDate = this.dateHelpers.formatTable(date)
      return fDate;
    },


  },
};
</script>

<style scoped lang="scss">
  @import './../assets/styles/sections-app.css';
</style>