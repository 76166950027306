<template>
            <div class="opps" v-if="prepaid">
                    <div class="opps-header">
                        <div class="opps-reminder">Ficha digital. No es necesario imprimir.</div>
                        <div class="opps-info">
                            <div class="opps-brand">
                                <v-img
                                    :src="require('@/assets/app/oxxopay_brand.png')"
                                    alt="Oxxo Pay"
                                    />
                            </div>
                            <div class="opps-ammount">
                                <h3>Monto a pagar</h3>
                                <h2>{{amountFormatted}} <sup>MXN</sup></h2>
                                <p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
                            </div>
                        </div>
                        <div class="opps-reference">
                            <h3>Referencia</h3>
                            <h1>{{prepaid.reference}}</h1>
                        </div>
                    </div>
                    <div class="opps-instructions">
                        <h3>Instrucciones</h3>
                        <ol>
                            <li>Acude a la tienda OXXO más cercana. <a href="https://www.google.com.mx/maps/search/oxxo/" target="_blank">Encuéntrala aquí</a>.</li>
                            <li>Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>
                            <li>Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.</li>
                            <li>Realiza el pago correspondiente con dinero en efectivo.</li>
                            <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
                        </ol>
                        <div class="opps-footnote">Al completar estos pasos recibirás un correo de <strong>Ciphra Cashless</strong> confirmando tu pago.</div>
                    </div>
                </div>
</template>
<script>
  export default {
    props: {
        prepaid: {
            type: Object,
        },
    },
    components: {
    },
    data: () => ({
      
    }),
    computed:{
        amountFormatted(){
        return new Intl.NumberFormat("en-US",{
          style: 'currency',
          currency: 'USD',
          }).format(this.prepaid.amount);
      },
    },
    methods: {
    
    }
  }
</script>
<style scoped>
* 	 { margin: 0;padding: 0; }
body { font-size: 14px; }

/* OPPS --------------------------------------------------------------------- */

h3 {
	margin-bottom: 10px;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
}

.opps {
	width: 510px; 
	border-radius: 4px;
	box-sizing: border-box;
	padding: 0 45px;
	margin: 40px auto;
	overflow: hidden;
	border: 1px solid #b0afb5;
	font-family: 'Open Sans', sans-serif;
	color: #4f5365 impo !important;
    background: #FFFFFF !important;
}

.opps-reminder {
	position: relative;
	top: -1px;
	padding: 9px 0 10px;
	font-size: 11px;
	text-transform: uppercase;
	text-align: center;
	color: #ffffff !important;
	background: #000000;
}

.opps-info {
	margin-top: 26px;
	position: relative;
}

.opps-info:after {
	visibility: hidden;
     display: block;
     font-size: 0;
     content: " ";
     clear: both;
     height: 0;

}

.opps-brand {
	width: 45%;
	float: left;
}

.opps-brand img {
	max-width: 150px;
	margin-top: 2px;
}

.opps-ammount {
	width: 55%;
	float: right;
}

.opps-ammount h2 {
	font-size: 36px;
	color: #000000;
	line-height: 24px;
	margin-bottom: 15px;
}

.opps-ammount h2 sup {
	font-size: 16px;
	position: relative;
	top: -2px
}

.opps-ammount p {
	font-size: 10px !important;
	line-height: 14px !important;
}

.opps-reference {
	margin-top: 14px;
    text-align: left;
}
h1 {
	font-size: 27px !important;
	color: #000000 !important;
	text-align: center;
	margin-top: -1px;
	padding: 6px 0 7px;
	border: 1px solid #b0afb5 !important;
	border-radius: 4px;
	background: #F8F8F8 !important;
}

.opps-instructions {
	margin: 32px -45px 0;
	padding: 32px 45px 45px;
	border-top: 1px solid #b0afb5 !important;
	background: #FFFFFF !important;
    text-align: left !important;
    font-weight: bold;
    font-size: 13px;
}

ol {
	margin: 17px 0 0 16px;
}

li + li {
	margin-top: 10px;
	color: #000000 !important;
}

a {
	color: #000000 !important;
    text-decoration: none;
}

.opps-footnote {
	margin-top: 22px;
	padding: 22px 20 24px;
	color: #108f30 !important;
	text-align: center;
	border: 1px solid #108f30;
	border-radius: 4px;
	background: #ffffff;
}
.opps-footnote strong{
    color: #108f30 !important;
}
</style>