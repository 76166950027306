<template>
  <v-layout align-center justify-center>
    <business-url />
  </v-layout>
</template>

<script>
// @ is an alias to /src
import BusinessUrl from '@/components/BusinessUrl.vue'

export default {
  components: {
    BusinessUrl
  }
}
</script>
