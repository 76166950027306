<template>
    <v-dialog
    v-model="dialog"
    max-width="300"
  >
    <v-card>
      <v-card-text>
		  <v-textarea
			v-model="item.comments"
			placeholder="Agregar comentarios"
			dense
			></v-textarea>
	  </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
		  	color="secondary"
            text
			rounded
			@click="dialog = false">Cancelar</v-btn>
            <v-spacer></v-spacer>
          <v-btn 
		  	color="purple"
            text
			rounded
			 :loading="isSavingData"
			@click.native="saveData()">Guardar</v-btn>
			<v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
	<snack-bar-message ref="snackMessages"/>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import SnackBarMessage from './SnackBarMessage.vue';

export default {
    name: 'CommentModal',
	components: {
        SnackBarMessage
    },
    data: () => ({
        dialog: false,
		isSavingData: false,
		item: {
			comments: ""
		}
    }),
    methods: {
        /**
  	  * open description
  	  * Habre el modal.
  	  */
 	 open(data){
 	 	this.dialog = true

		this.item._id = data._id;
		this.item.comments = data.comments;
 	 },

	saveData(){
		this.isSavingData = true;
		axios.post('refunds/updateComment',this.item).then(response => {
			this.isSavingData = false;
			this.$bus.$emit('refreshRefundsList', true);
			this.dialog = false;
		}).catch(err => {
			this.isSavingData = false;
			this.$refs.snackMessages.show('Ocurrio un error al guardar. Asegurese llenar todos los campos requeridos.');
		});
	},
    }
}
</script>
<style scoped>
>>> .v-dialog > .v-card > .v-card__text{
	padding-top: 10px
}
>>> .v-toolbar__title{
    margin: 0 auto;
    font-size: 14px;
    font-weight: bold;
}
>>> .v-card__text{
    color: #000 !important;
}
</style>