<template>
<v-dialog
      v-model="dialogOpen"
      persistent
      max-width="640"
    >
      <v-card class="form-tabs">
        <v-toolbar color="white" flat>
            <v-toolbar-title>{{isEdit ? 'Editar colaborador':'Agregar colaborador'}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogOpen = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-form ref="formItem" 
            @submit.prevent="submit"
            v-model="validData">
                <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                            <v-select
                                v-model="item.role"
                                :items="role_items"
                                item-text="description"
                                item-value="name"
                                label="Rol del colaborador"
                                dense
                            :rules="rules.required"
                            ></v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                            <v-select
                                v-model="item.terminal_id"
                                :items="terminals"
                                item-text="name"
                                item-value="_id"
                                label="Terminal de venta u operación"
                                dense
                            :rules="rules.required"
                            ></v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                            <v-text-field
                                v-model="item.name"
                                :rules="rules.required"
                                label="Nombre completo"
                                required
                                dense
                                ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                            <v-text-field
                                v-model="item.email"
                                :rules="rules.validEmail"
                                label="Email"
                                required
                                dense
                                ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            :hidden="isEdit ? true: false"
                        >
                            <v-text-field
                                v-model="item.password"
                                :rules="!isEdit ? rules.required:[]"
                                :type="showedPassword ? 'text' : 'password'"
                                label="Contraseña"
                                required
                                dense
                                >
                                <template v-slot:append>
                                    <unicon name="eye" fill="#5A5A5A" v-if="!showedPassword" v-on:click="togglePassword"></unicon>
                                    <unicon name="eye-slash" fill="#5A5A5A" v-else v-on:click="togglePassword"></unicon>
                                </template> 
                                </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
             </v-form>
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogOpen = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="black"
            rounded
            :dark="validData"
            :loading="isSavingData"
            :disabled="!validData"
            @click="saveData()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
    </v-card>
    <snack-bar-message ref="snackMessages"/>
</v-dialog>
</template>
<script>
import axios from 'axios';
import SnackBarMessage from './SnackBarMessage.vue';

export default {
    props: ["business", "terminals"],
    components: {
        SnackBarMessage
    },
    data () {
      return {
        validData: false,
        dialogOpen: false,
        isSavingData: false,
        isEdit: false,
        showedPassword: false,
        role_items: [
            {name: 'admin', description: 'Administrador'},
            {name: 'operator', description: 'Operador'},
            {name: 'seller', description: 'Vendedor'},
        ],
        item: {
            role: null,
            terminal_id: null,
            business_id: null,
            name: null,
            email: null,
            password: null,
        },
        rules: {
            required: [
                v => !!v || 'Campo requerido.',
            ],
            validEmail: [
                (v) => !!v || 'El campo es requerido',
                (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(v) || 'Email no válido'
            ],
        }
      }
    },
    methods: {
        togglePassword() {
            this.showedPassword = !this.showedPassword;
        },
        reset () {
            this.$refs.formItem.reset()
            delete this.item._id;
        },
        resetValidation () {
            this.$refs.formItem.resetValidation()
        },
        open() {
            this.dialogOpen = true;
            this.reset();
            this.resetValidation();
            delete this.item._id;
            this.isEdit = false;
        },
        edit(item){
            this.dialogOpen = true;
            this.item = item;
            this.item.terminal_id = item.terminal._id;
            this.item.role = item.role.name;
            this.isEdit = true;
        },
        saveData(){
            this.item.business_id = this.business._id;
            this.isSavingData = true;
            if(this.isEdit){
                this.item.password = '';
            }
            const saveUrl = this.isEdit ? 'collaborators/update' : 'collaborators/add';
            axios.post(saveUrl,this.item).then(response => {
                this.isSavingData = false;
                this.reset();
                this.$bus.$emit('refreshCollaboratorsList', true);
                this.dialogOpen = false;
            }).catch(err => {
                this.isSavingData = false;
                this.$refs.snackMessages.show('Ocurrio un error al guardar. Asegurese llenar todos los campos requeridos.');
            });
        },
    },
    created(){
    }
  }
</script>
<style scoped>
.form-tabs .v-tabs-items{
    overflow: hidden !important;
    padding-top: 10px !important;
    height: 325px !important;
}
.form-tabs .row{
    margin: initial !important;
}
.form-tabs textarea{
    height: 230px !important;
}
.v-text-field__prefix{
    color: #000000 !important;
    font-weight: bold !important;
}
</style>