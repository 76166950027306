<template>
  <v-app :class="classTemplate"
  >
    <v-overlay v-if="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <app-drawer v-if="loggedIn && !isOpenRoute"/>
    <app-top-bar v-if="loggedIn && !isOpenRoute"/>
    <v-main>
      <v-container fill-height fluid>
        <v-fade-transition mode="out-in">
          <router-view></router-view>
        </v-fade-transition>
      </v-container>
      <app-footer v-if="!isOpenRoute"/>
    </v-main>
  </v-app>
</template>

<script>
import AppDrawer from '@/components/Layout/AppDrawer.vue' 
import AppTopBar from '@/components/Layout/AppTopBar.vue'
import AppFooter from '@/components/Layout/AppFooter.vue'
export default {
  name: 'App',
  components: {
    AppDrawer,
    AppTopBar,
    AppFooter,
  },
  data: () => ({
    loading: false,
    }),
    methods: {
      loadGlobalListeners () {
            this.$bus.$off('toggleLoading');

            this.$bus.$on('toggleLoading', (isLoading) => {
              this.loading = isLoading;
            });
      },
    },
    computed:{
      classTemplate(){
        let classTemplate = 'non-auth';
        if(this.isOpenRoute){
          classTemplate = 'open-layout';
        }else if(this.loggedIn){
          classTemplate = 'authorized';
        }
        return classTemplate;
      },
      isOpenRoute(){
        const openRoutes = ['business_prepaid'];
        return openRoutes.includes(this.$route.name);
      },
      loggedIn(){
        return this.$store.getters.isLoggedIn;
      },
      routeName(){
        return this.$route.name;
      }
    },
    beforeCreate() {
      //Check login
      const routeName = this.$route.name;
      if(routeName != 'business_prepaid'){
        this.$store.dispatch('autoLogin');
      }
    },
    created(){
      console.log(this.router);
      this.loadGlobalListeners();
    }
};
</script>
<style scoped>
  @import './assets/styles/application.css';
  .non-auth{
    background: #8AD7CA url('./assets/app/img-background-mint.svg') no-repeat center center fixed !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
  }
  .authorized{
    background-color: #F8F8F8 !important;
  }
  .open-layout{
    overflow: hidden !important;
  }
  .open-layout .container{
    padding: 0 !important;
  }
</style>