import moment from 'moment'
moment.updateLocale('es', {
  weekdaysShort: [
    "Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sáb"
  ],
  monthsShort : [
          "ene", "feb", "mar", "abr", "may", "jun",
          "jul", "ago", "sep", "oct", "nov", "dic"
      ]
});

const formatTable = (d, f = 'DD MMM YYYY') => d && moment(d).isValid() && moment(d).utcOffset(0).format(f)
const formatInput = (d, f = 'ddd, D MMM YYYY') => d && moment(d).isValid() && moment(d).utcOffset(0).format(f)

export default {
  formatTable,
  formatInput,
}