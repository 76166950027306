<template>
<v-dialog
    v-model="dialogBusinessOpen"
    persistent
    max-width="640">
    <v-card class="form-tabs">
        <v-toolbar color="white" flat>
            <v-toolbar-title>{{isEdit ? 'Editar negocio':'Agregar negocio'}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogBusinessOpen = false">
                <v-icon>close</v-icon>
            </v-btn>

            <template v-slot:extension>
                <v-tabs
                    v-model="currentTab"
                    align-with-title >
                    <!-- @change="onTabChange" -->
                    <v-tabs-slider color="black"></v-tabs-slider>
                    <v-tab
                        v-for="(item, index) in sections"
                        :key="index"
                        @click="tabClicked(index)">
                        {{ item }}
                    </v-tab>
                </v-tabs>
            </template>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
            <v-form ref="formBusiness" 
            @submit.prevent="submit"
            lazy-validation
            v-model="validData">
                <v-container>
                    <v-tabs-items v-model="currentTab">
                        <v-tab-item>
                            <v-row>

                                <v-col cols="6">
                                    <div class="container-input-image">
                                        <img :src="imageUrl" class="img-selected-form" />
                                        <div class="button-add-img" @click="selectFile">
                                            <v-icon color="white" x-large>add</v-icon>
                                        </div>
                                        <span class="title-add-img">Logo</span>
                                        <input type="file" 
                                            ref="fileInput"
                                            @change="onFileChange" 
                                            accept="image/*"
                                            style="display: none;"/>
                                    
                                    </div>
                                </v-col>

                                <v-col cols="6">
                                    <div class="container-input-image">
                                    <img :src="imageUrlPortada" class="img-selected-form" />
                                    <div class="button-add-img" @click="selectFilePortada">
                                        <v-icon color="white" x-large>add</v-icon>
                                    </div>
                                    <span class="title-add-img">Portada</span>
                                    <input type="file" 
                                        ref="fileInputPortada"
                                        @change="onFileChangePortada" 
                                        accept="image/*"
                                        style="display: none;"/>
                                       
                                    </div>
                                    
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="business.name"
                                        label="Nombre del negocio *"
                                        dense
                                        :rules="rules.required">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" >
                                    <v-select
                                        v-model="business.category"
                                        :items="category_items"
                                        item-text="name"
                                        item-value="category_id"
                                        label="Clasificación *"
                                        dense
                                        :rules="rules.required"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                        label="Ruta de pago"
                                        v-model="business.url"
                                        dense
                                        :prefix="publicProfilePays"
                                        :rules="rules.required" />
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea
                                        v-model="business.description"
                                        label="Descripción del evento o negocio *"
                                        counter
                                        dense
                                        no-resize
                                        :rules="rules.required"/>
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <!-- Form Domicilio -->
                        <v-tab-item>
                            <v-row>
                                <v-col cols="12" sm="12" md="12" >
                                <v-text-field
                                    label="Domicilio *"
                                    v-model="business.address"
                                    dense
                                    :rules="rules.required"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6" >
                                <v-text-field
                                    label="Código postal *"
                                    v-model="business.postal_code"
                                    dense
                                    :rules="rules.required"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" >
                                <v-text-field
                                    label="Ciudad *"
                                    v-model="business.city"
                                    dense
                                    :rules="rules.required"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12"
                                    sm="6"
                                    md="6"
                                >
                                <v-text-field
                                    label="Estado *"
                                    v-model="business.state"
                                    dense
                                    :rules="rules.required"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                >
                                <v-text-field
                                    label="País *"
                                    v-model="business.country"
                                    dense
                                    :rules="rules.required"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <!-- Facturación -->
                        <v-tab-item>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                >
                                <v-text-field
                                    label="Razón social *"
                                    v-model="business.legal_entity"
                                    dense
                                    :rules="rules.required"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                >
                                <v-text-field
                                    label="Clave de negocio *"
                                    v-model="business.business_key"
                                    dense
                                    counter
                                    maxlength="3"
                                    :rules="rules.required"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                >
                                <v-text-field
                                    label="RFC *"
                                    v-model="business.rfc"
                                    dense
                                    :rules="rules.required"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                >
                                <v-select
                                v-model="business.invoice_usage"
                                :items="invoice_usage_items"
                                item-text="name"
                                item-value="usage_id"
                                label="Uso de CFDI *"
                                dense
                                :rules="rules.required"
                                ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                >
                                <v-text-field
                                    label="Domicilio fiscal *"
                                    v-model="business.tax_residence"
                                    dense
                                    :rules="rules.required"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                >
                                <v-text-field
                                    label="Email de facturación *"
                                    v-model="business.invoice_email"
                                    dense
                                    :rules="rules.validEmail"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-tab-item>

                    </v-tabs-items>
                </v-container>
             </v-form>
        </v-card-text>
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogBusinessOpen = false">
            Cancelar
          </v-btn>
          <v-btn
            color="black"
            rounded
            :dark="validData"
            :loading="isSavingData"
            :disabled="!validData"
            @click="nextTab()"
          >
            {{isEndTab ? 'Guardar' : 'Siguiente'}}
          </v-btn>
        </v-card-actions>
    </v-card>
    <snack-bar-message ref="snackMessages"/>
</v-dialog>
</template>
<script>
import axios from 'axios';
import SnackBarMessage from './SnackBarMessage.vue';


export default {
    components: {
        SnackBarMessage
    },
    data () {
      return {
        imageUrl: require('../../assets/app/upload-img.svg'),
        imageUrlPortada: require('../../assets/app/upload-img.svg'),

        imageData:  null,
        imagePortadaData:  null,

        urlPagos: process.env.VUE_APP_URL_PAGOS,
        validData: false,
        dialogBusinessOpen: false,
        isSavingData: false,
        isEdit: false,
        allowTabChange : false,
        logo: [],
        cover:[],
        business: {
            name: null,
            category: null,
            url: null,
            description: '',
            address: null,
            postal_code: null,
            city: null,
            state: null,
            country: null,
            legal_entity: null,
            business_key: null,
            rfc: null,
            invoice_usage: null,
            tax_residence: null,
            invoice_email: null,
        },

        category_items:[],
        invoice_usage_items: [],
        currentTab: 0,
        previousTab: 0,
        sections: [
          'Generales', 'Descripción', 'Domicilio', 'Facturación',
        ],
        rules: {
            required: [
                v => !!v || 'Campo requerido.',
            ],
            validEmail: [
                (v) => !!v || 'El campo es requerido',
                (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(v) || 'Email no válido'
            ],
        }
      }
    },
    computed: {
        isEndTab(){
            return this.currentTab >= this.sections.length - 1;
        },
        publicProfilePays(){
            return `Perfil público: ${this.urlPagos}`;
        }
    },
    methods: {
        selectFile() {
            this.$refs.fileInput.click();
        },
        onFileChange(event) {
            const file = event.target.files[0]; // Obtiene el archivo seleccionado
            if (file && file.type.startsWith('image/')) {
                this.imageData = file
                this.imageUrl = URL.createObjectURL(file);  // Crea una URL de la imagen para vista previa
            } else {
                alert("Por favor selecciona un archivo de imagen válido.");
            }
        },

        selectFilePortada() {
            this.$refs.fileInputPortada.click();
        },
        onFileChangePortada(event) {
            const file = event.target.files[0]; // Obtiene el archivo seleccionado
            if (file && file.type.startsWith('image/')) {
                this.imagePortadaData = file
                this.imageUrlPortada = URL.createObjectURL(file);  // Crea una URL de la imagen para vista previa
            } else {
                alert("Por favor selecciona un archivo de imagen válido.");
            }
        },

        getImage(id){
            const me = this
            axios.get('files/image?linked_to='+id)
            .then(function (response){
                console.log(response.data.url)
                me.imageUrl = response.data.url
            })

        },

        async tabClicked(clickedTab){
			//const isValid = this.$refs.formBusiness.validate();
            
            //this.currentTab = clickedTab;
            //this.previousTab = this.currentTab
            //this.$refs.formBusiness.validate()
			//if (!isValid) this.currentTab = this.previousTab;
			//else this.previousTab = this.currentTab;
            

            /*console.log(index);
            if (this.$refs.formBusiness.validate()) {
                console.log('aqui');
                this.currentTab = index;
                this.previousTab = this.currentTab;
            }else{
                console.log('aca');
               this.currentTab = this.previousTab; 
            }*/
            
            /*this.currentTab = clickedTab;
			const isValid = this.$refs.formBusiness.validate();
			await this.$nextTick();
            console.log(isValid);
			if (!isValid) this.currentTab = this.previousTab;
			else this.previousTab = this.currentTab;*/
        },
        reset () {
            this.$refs.formBusiness.reset()
            delete this.business._id;
            delete this.business.logo;
        },
        resetValidation () {
            this.$refs.formBusiness.resetValidation()
        },
        nextTab(){
           if (this.$refs.formBusiness.validate()) {
            if(this.currentTab < (this.sections.length - 1)){
                this.currentTab++;
                this.previousTab = this.currentTab;
            }else{
                this.saveData();
            }
           }else{//Tiene campos pendientes por llenar y ya llego al ultimo paso
               if(this.isEndTab){
                   this.$refs.snackMessages.show('Es necesario proporcionar todos los campos requeridos.');
                   console.log('is end tab error');
               }
           }
        },
        open(){
            this.dialogBusinessOpen = true;
            this.currentTab = 0;
            this.reset();
            this.resetValidation();

            delete this.business._id;
            this.logo = [];
            this.cover = [];

            this.isEdit = false;
        },
        edit(business){
            this.dialogBusinessOpen = true;
            this.currentTab = 0;
            //this.reset();
            //this.resetValidation();
            this.business = business;
            this.business.category = business.category.category_id;
            this.business.invoice_usage = business.invoice_usage.usage_id;
            this.logo = [];
            this.cover = [];
            console.log("=====")
            console.log(business._id)
            this.getImage(business._id)
            this.isEdit = true;
        },
        saveData(){
            const me = this
            const userData = this.$store.state.user
            const formData = new FormData();
            formData.append('data', JSON.stringify(this.business));
            formData.append('logo', this.logo);
            formData.append('cover', this.cover);
            //formData.append('images', this.cover);
            this.isSavingData = true;
            const saveUrl = this.isEdit ? 'business/update' : 'business/add';
            axios.post(saveUrl,formData,{
                   'Content-type': 'multipart/form-data',
                }).then(async response => {

                if(me.imageData != null ){
                    const formDataImg = new FormData();
                    formDataImg.append('file', this.imageData);
                    formDataImg.append('name', response.data._id + "BUS"); // Agregar un nombre al archivo
                    formDataImg.append('uploaded_by',userData._id);
                    formDataImg.append('linked_to', response.data._id);
                    
                    try {
                        const response = await axios.post('files/upload', formDataImg, {
                        headers: {
                            'Content-Type': 'multipart/form-data' // Especificar que es un envío de archivo
                        }
                        });
                    } catch (error) {
                        console.error('Error al enviar el archivo', error);
                    }
                }

                if(me.imagePortadaData != null ){
                    const formDataImg = new FormData();
                    formDataImg.append('file', this.imagePortadaData);
                    formDataImg.append('name', response.data._id + "LOG"); // Agregar un nombre al archivo
                    formDataImg.append('uploaded_by',userData._id);
                    formDataImg.append('linked_to', response.data._id);
                    try {
                        const response = await axios.post('files/upload', formDataImg, {
                        headers: {
                            'Content-Type': 'multipart/form-data' // Especificar que es un envío de archivo
                        }
                        });
                    } catch (error) {
                        console.error('Error al enviar el archivo', error);
                    }
                }


                this.isSavingData = false;
                this.reset();
                this.$bus.$emit('refreshBusinessList', true);
                this.dialogBusinessOpen = false;
            }).catch(err => {
                this.isSavingData = false;
            });
        },

        listBusinessCategories(){
            axios.get('business/categories').then(response => {
                this.category_items = response.data;
            }).catch(err => {
            });
        },

        listInvoiceUsage(){
            axios.get('business/invoiceUsages').then(response => {
                this.invoice_usage_items = response.data;
            }).catch(err => {
            });
        },

    },
    created(){
        this.listBusinessCategories();
        this.listInvoiceUsage();
    }
  }
</script>
<style scoped>
.form-tabs .v-tabs-items{
    overflow: hidden !important;
    padding-top: 10px !important;
    height: 325px !important;
}
.form-tabs .row{
    margin: initial !important;
}
.form-tabs textarea{
    height: 230px !important;
}
.v-text-field__prefix{
    color: #000000 !important;
    font-weight: bold !important;
}
</style>