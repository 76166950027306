<template>
  <v-layout align-center justify-center>
    <reset-password />
  </v-layout>
</template>

<script>
// @ is an alias to /src
import ResetPassword from '@/components/Login/ResetPassword.vue'

export default {
  components: {
    ResetPassword
  }
}
</script>
