import Vue from 'vue'

import moment from 'moment';
import VueMoment from 'vue-moment';

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios';
import Unicon from 'vue-unicons'
import dateHelpers from '@/helpers/dateHelpers'
import numberHelpers from '@/helpers/numberHelpers'

Vue.prototype.dateHelpers = dateHelpers
Vue.dateHelpers = dateHelpers

Vue.prototype.numberHelpers = numberHelpers
Vue.numberHelpers = numberHelpers

Vue.use(VueMoment, { moment });

import { uniEdit, uniEnvelope, uniLock, uniEye, uniEyeSlash,
  uniRepeat, uniUserCircle, uniAngleDown, uniAngleUp, uniBrowser,
  uniSignout, uniApps, uniBag, uniTrafficLight, uniTransaction, uniMoneyWithdrawal,
  uniMoneyInsert, uniShop, uniUsersAlt, uniEllipsisH, uniEllipsisV, uniLockAlt, 
  uniKeySkeletonAlt, uniQuestionCircle, uniBookOpen, uniShieldCheck, uniSearch, 
  uniCreditCard, uniReceiptAlt, uniTrafficBarrier, uniCloudDownload, uniCommentAdd, 
  uniRedo, uniTagAlt, uniCalculator } from 'vue-unicons/src/icons'

const bus = new Vue({});

Unicon.add([ uniEdit, uniEnvelope, uniLock, uniEye, uniEyeSlash,
  uniRepeat, uniUserCircle, uniAngleDown, uniAngleUp, uniBrowser,
  uniSignout, uniApps, uniBag, uniTrafficLight, uniTransaction, uniMoneyWithdrawal,
  uniMoneyInsert, uniShop, uniUsersAlt, uniEllipsisH, uniEllipsisV, uniLockAlt, 
  uniKeySkeletonAlt, uniQuestionCircle, uniBookOpen, uniShieldCheck, uniSearch,
  uniCreditCard, uniReceiptAlt, uniTrafficBarrier, uniCloudDownload, uniCommentAdd, 
  uniRedo, uniTagAlt, uniCalculator])
Vue.use(Unicon)

if (!localStorage.token) {
  // Si no existe, agrega el item vacio
  localStorage.setItem("token", "");
}

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT || 'http://localhost:3000/api/v1/';
axios.defaults.headers = {
  token: localStorage.getItem("token")
}
Object.defineProperty(Vue.prototype, "$bus", {
  get() {
    return this.$root.bus;
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  data: {
    bus: bus
  },
  created() {
    console.log("Mounted application");
  }
}).$mount('#app')
